<template>
  <div id="map"></div>
</template>

<script setup>
  import "ol/ol.css";
  import { onMounted } from "vue";
  import { Map, View } from "ol";
  import { Tile } from "ol/layer";
  import { TileArcGISRest } from "ol/source";
  import { ATTRIBUTIONS } from "@/constants";
  import updateMapSize from "@/hooks/updateMapSize";

  const arcGISSource = new TileArcGISRest({
    attributions: ATTRIBUTIONS,
    //ArcGIS Rest服务url,url中包括MapServer或ImageServer
    url:
      "http://sampleserver1.arcgisonline.com/ArcGIS/rest/services/" +
      "Specialty/ESRI_StateCityHighway_USA/MapServer",
  });
  const arcGISLayers = new Tile({
    source: arcGISSource,
    extent: [-13884991, 2870341, -7455066, 6338219],
  });
  const initMap = () => {
    return new Map({
      //初始化map
      target: "map",
      //地图容器中加载的图层
      layers: [],
      //地图视图设置
      view: new View({
        //地图初始中心点
        center: [-10997148, 4569099],
        zoom: 5, //地图初始显示级别
      }),
    });
  };
  onMounted(() => {
    const map = initMap();
    //添加瓦片地图图层
    map.addLayer(arcGISLayers);
    // 侧边栏变化更新地图
    updateMapSize(map);
  });
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  #map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
</style>
